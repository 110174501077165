import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Push Jerk 5×2\\@80% 1RM`}</p>
    <p>{`Band Pull Aparts 5×10`}</p>
    <p>{`then,`}</p>
    <p>{`30-Calorie Row`}</p>
    <p>{`25-Shoulder Press (95/65)`}</p>
    <p>{`25-Calorie Row`}</p>
    <p>{`20-Push Press (115/75)`}</p>
    <p>{`20-Calorie Row`}</p>
    <p>{`15-Push Jerk (135/95)`}</p>
    <p>{`15-Calorie Row`}</p>
    <p>{`10-Split Jerk (155/105)`}</p>
    <p>{`10-Calorie Row`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      